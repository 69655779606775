<template>
  <SubscriptionCommunityPage
    v-if="isNeedShowOldCommunityTab"
    :filtered-list-of-collections="filteredListOfCollections"
    :props-data-for-searching="propsDataForSearching" />
  <Collections
    v-else-if="activeHeader"
    is-community-collections
    hide-column-members
    :can-use-quick-view-link="canUseQuickViewLink"
    :expanded-collections="expandedCollections"
    :filtered-list-of-collections="filteredListOfCollections"
    :props-data-for-searching="propsDataForSearching"
    :is-workspace-edit-page="isWorkspaceEditPage"
    :is-workspace-page="isWorkspacePage">
    <template #createButtonActions>
      <CollectionsCreateProductDialog />
    </template>
    <template
      #expandedIcon="{ group }">
      <p
        class="mb-0 d-flex align-center"
        @click="changeCollectionsToShow({
          group,
        })">
        <a class="text-uppercase">
          {{ setExpandTitle({group}) }}
        </a>
      </p>
    </template>
  </Collections>
</template>
<script>
import Collections from '@/components/Collections';
import SubscriptionCommunityPage from '@/components/Collections/SubscriptionCommunityPage';
import SearchCollections from '@/services/graphql/searching/searchCollections';
import SearchProducts from '@/services/graphql/searching/searchProducts';
import SearchCollectionsAndProducts
  from '@/services/graphql/searching/searchCollectionsAndProducts';
import SearchWorkspacePages from '@/services/graphql/searching/searchWorkspacePages';
import { mapGetters } from 'vuex';
import { ROUTE_COMMUNITY_COLLECTIONS } from '@/constants';
import CollectionsCreateProductDialog
  from '@/components/Collections/CollectionsCreateProductDialog';
import getInfo from '@/mixins/getInfo';
import {
  COLLECTIONS_CRITERIA,
  SEARCH_COMMUNITY_COLLECTIONS_V3,
  SEARCH_WORKSPACE_PAGES,
  WORKSPACE_PAGES_CRITERIA,
  SEARCH_COMMUNITY_PRODUCTS_V3,
  PRODUCTS_CRITERIA,
} from '@/constants/searching/searchingAsyncV2';
export default {
  name: 'CommunityCollections',
  components: {
    Collections,
    CollectionsCreateProductDialog,
    SubscriptionCommunityPage,
  },
  mixins: [getInfo],
  props: {
    isWorkspacePage: {
      type: Boolean,
      default: false,
    },
    isWorkspaceEditPage: {
      type: Boolean,
      default: false,
    },
    /**
     * Indicates whether the quick view link can be used.
     *
     * @type {boolean}
     * @default false
     */
    canUseQuickViewLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedCollections: {
      },
    };
  },
  computed: {
    ...mapGetters({
      getCollectionsGroups: 'Collections/getAppropriateCollectionsGroupsByCriteria',
      getCommunityCollectionGroup: 'Collections/getCommunityCollectionGroup',
      verifiedAreaListing: 'FeatureFlags/verifiedAreaListing',
      getDefaultLibraryId: 'Libraries/getDefaultLibraryId',
    }),
    routeName() {
      return [ROUTE_COMMUNITY_COLLECTIONS];
    },
    filteredListOfCollections() {
      const { getCollectionsGroups, routeName } = this;
      if (this.isWorkspacePage) {
        return [this.getCommunityCollectionGroup];
      }
      return getCollectionsGroups({
        routeName,
      });
    },
    propsDataForSearching() {
      const arrNameRequest = [SEARCH_COMMUNITY_COLLECTIONS_V3, SEARCH_COMMUNITY_PRODUCTS_V3, SEARCH_WORKSPACE_PAGES];
      return {
        searchPlaceholder: 'Search to discover new pages and collections...',
        asyncCollectionsApiMethod: {
          request: SearchCollections['asyncCommunityCollectionsV3'],
          nameRequest: SEARCH_COMMUNITY_COLLECTIONS_V3,
        },
        asyncProductsApiMethod: {
          request: SearchProducts['asyncCommunityProductsV3'],
          nameRequest: SEARCH_COMMUNITY_PRODUCTS_V3,
        },
        searchWorkspacePages: {
          request: SearchWorkspacePages.search,
          nameRequest: SEARCH_WORKSPACE_PAGES,
        },
        arrNameRequest,
        orderOfSections: [
          WORKSPACE_PAGES_CRITERIA,
          COLLECTIONS_CRITERIA,
          PRODUCTS_CRITERIA,
        ],
        asyncCollectionsAndProductsApiMethod: {
          request: SearchCollectionsAndProducts['asyncCommunityCollectionsAndProductsWithPageV3'],
        },
        libraryId: this.getDefaultLibraryId,
      };
    },
    isNeedShowOldCommunityTab() {
      return this.verifiedAreaListing &&
        this.$route.name === 'community-collections';
    },
  },
  methods: {
    setExpandTitle({ group }) {
      return this.expandedCollections[group] ?
        'collapsed view' : 'see all';
    },
    changeCollectionsToShow({ group }) {
      this.expandedCollections = {
        ...this.expandedCollections,
        [group]: !this.expandedCollections[group],
      };
    },
  },
};
</script>
